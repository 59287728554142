import { environment } from '../../../environments/environment';

export class ConfigConst {
  static baseURL = environment.baseURL;
  static proxy = environment.proxy;
  static APICerto = environment.APICerto;
  static API = environment.API;
  static Emalote = environment.Emalote;
  static AWSIntegrations = environment.AWSIntegrations;
  static AWSAuthService = environment.AWSAuthService;
  static AWSImpostograma = environment.AWSImposto;
  static AWSUploads = environment.AWSUploads;
  static AWSIa = environment.AWSIa;
  static contratarPacote = environment.contratarPacote;
  static TextoContratar = environment.TextoContratar;

  static ID_Servico_Recuperacao = environment.ID_Servico_Recuperacao;

  static dashmodulointegracao = environment.dashmodulointegracao;
  static calcularguia = environment.calcularguia;

  static Ambiente = environment.Ambiente;
  static gaTag = environment.gaTag;

  static BucketUpload = environment.BucketUpload;
  static BucketEmalote = environment.Bucket;
  static BucketTMP = environment.BucketTMP;

  static APP = environment.APP;

  static AWS = environment.AWS;

  static AWSAuth = environment.AWSAuth;

  static IGAuth =
    'YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy';

  static SuperLogicaToken = environment.SuperLogicaToken;
  static SuperLogicaAccessToken = environment.SuperLogicaAccessToken;
  static SuperlogicaDomain = environment.SuperlogicaDomain;

  static Permissoes = 'N';
  static Bucket = environment.Bucket;
  static auditoria = environment.auditoria;
  static Contabilidade = environment.Contabilidade;
  static contabilidade = environment.contabilidade;
  static aContabilidade = environment.aContabilidade;
  static umaContabilidade = environment.umaContabilidade;
  static daContabilidade = environment.daContabilidade;
  static dasContabilidades = environment.dasContabilidades;
  static nasContabilidades = environment.nasContabilidades;
  static recuperarsenha = environment.recuperarsenha;
  static novasenha = environment.novasenha;
  static mensagemreativar = environment.mensagemreativar;
  static Contabilidadeatualizar = environment.Contabilidadeatualizar;
  static mensagemjacadastradoContabilidade =
    environment.mensagemjacadastradoContabilidade;
  static selecionarcontabilidade = environment.selecionarcontabilidade;
  static SelecionarumaContabilidade = environment.SelecionarumaContabilidade;
  static continuarCadastrar = environment.continuarCadastrar;
  static messagemdeletarusuario = environment.messagemdeletarusuario;
  static titleContabilidade = environment.titleContabilidade;
  static MinhasContabilidades = environment.MinhasContabilidades;
  static rotaminhascontabilidades = environment.rotaminhascontabilidades;
  static rotaadicionarcontabilidade = environment.rotaadicionarcontabilidade;
  static rotausuariocontabilidade = environment.rotausuariocontabilidade;
  static ContabilidadeAlteradaComSucesso =
    environment.ContabilidadeAlteradaComSucesso;
  static messagecadastro = environment.messagecadastro;
  static novaconta = environment.novaconta;
  static outracontabilidade = environment.outracontabilidade;
  static login = environment.login;
  static meuscontribuintes = environment.meuscontribuintes;
  static adicionarcontribuintes = environment.adicionarcontribuintes;
  static atualizarcontribuintes = environment.atualizarcontribuintes;
  static ativarconta = environment.ativarconta;
  static meuscertificados = environment.meuscertificados;
  static adicionarcertificado = environment.adicionarcertificado;
  static resetsenha = environment.resetsenha;
  static usuariocontribuinte = environment.usuariocontribuinte;
  static meusdados = environment.meusdados;
  static plugincaptura = environment.plugincaptura;
  static revisaotributaria = environment.revisaotributaria;
  static home = environment.home;
  static retorno = environment.retorno;
  static autenticar = environment.autenticar;
  static financeiro = environment.financeiro;
  static recuperarconta = environment.recuperarconta;
  static comece = environment.comece;
  static formularioPGDAS = environment.formularioPGDAS;
  static etapa = environment.etapa;
  static relatorio = environment.relatorio;
  static produtoemitente = environment.produtoemitente;
  static participanteemitente = environment.participanteemitente;
  static cenarios = environment.cenarios;
  static referencial = environment.referencial;
  static nfstomadas = environment.nfstomadas;
  static download = environment.download;
  static consultalivredeprodutos = environment.consultalivredeprodutos;
  static simuladordeoperacoes = environment.simuladordeoperacoes;
  static confrontarsped = environment.confrontarsped;
  static segregacao = environment.segregacao;
  static recuperacao = environment.recuperacao;
  static pgdas = environment.pgdas;
  static verdocumento = environment.verdocumento;
  static consultainterestadual = environment.consultainterestadual;
  static emalote = environment.emalote;
  static emalotedetails = environment.emalotedetails;
  static visaosituacional = environment.visaosituacional;
  static gerenciarintegracoes = environment.gerenciarintegracoes;
  static SubTituloUpgrade = environment.SubTituloUpgrade;

  static TextoUpgrade = environment.TextoUpgrade;
  static modulo = environment.modulos;
  static moduloTitle = environment.modulosTitle;

  static emailAtendimento = environment.emailAtendimento;
  static emailSustentacao = environment.emailSustentacao;
  static emailSustentacaoComercial = environment.emailSustentacaoComercial;

  static emailDemo = environment.emailDemo;
  static emailSuporte = environment.emailSuporte;
  static emailTributario = environment.emailTributario;

  static APPSite = environment.APPSite;

  static site = environment.site;

  static suporteauditoria = environment.suporteauditoria;
  static gerenciarprocessamentos = environment.gerenciarprocessamentos;
  static meusgestores = environment.meusgestores;
  static modulointegracao = environment.modulointegracao;
  static Trial = environment.Trial;

  static tituloModuloAuditoria = environment.tituloModuloAuditoria;
  static tituloModuloSegregacao = environment.tituloModuloSegregacao;
  static tituloModuloConsulta = environment.tituloModuloConsulta;
  static tituloModuloEmalote = environment.tituloModuloEmalote;
  static tituloModuloRevisao = environment.tituloModuloRevisao;

  static tituloBoxModuloAuditoria = environment.tituloBoxModuloAuditoria;
  static tituloBoxModuloSegregacao = environment.tituloBoxModuloSegregacao;
  static tituloBoxModuloConsulta = environment.tituloBoxModuloConsulta;
  static tituloBoxModuloEmalote = environment.tituloBoxModuloEmalote;
  static tituloBoxModuloRevisao = environment.tituloBoxModuloRevisao;
  static tituloBoxModuloRecuperacao = environment.tituloBoxModuloRecuperacao;
  static userImposto = environment.userImposto;
  static senhaImposto = environment.senhaImposto;
  static pluginCaptura = environment.pluginCaptura;

  static tipo = environment.tipo;
}
